$('[data-toggle="tooltip"]').tooltip();

$('#off-canvas').offcanvas({
  modifiers: 'left,reveal', //default options
  triggerButton: '#trigger-button', // btn to open offcanvas
});

// #############################################################################
// DATEPICKER

if ( $('[type="date"]').prop('type') != 'date' ) {
    $('[type="date"]').datepicker({
      'autoHide': true,
      'format': 'dd.mm.yyyy',
      'language': $('html').attr('lang'),
    });
}
